.print_page {
  padding: 20px;
  height: 100vh;
}

.print_page .invoice {
  padding: 10px;
  background-color: white;
  /* widows: 500px; */
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
  /* width: 500px; */
  /* margin: auto; */
  margin-bottom: 10px;
  max-width: 100%;
}

.invoice>div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: .2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.invoice>div:nth-of-type(2) {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  flex-direction: row-reverse;
}

.invoice>div:nth-of-type(2) * {
  padding: 0px;
  margin: 0px;
}

.invoice>div:nth-of-type(2)>div:nth-of-type(2)>div {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.invoice>div:nth-of-type(2)>div:nth-of-type(2)>div h4 {
  font-weight: 400;
  font-size: 18px;
}

.invoice .first_table {
  border: 1px solid #ccc;
  margin-top: 10px;
}

.invoice .first_table .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.invoice .first_table .top>* {
  width: 50%;
  padding: 10px;
  text-align: left;
  margin: 0px;
  font-weight: 600;
  font-size: 22px;
}

.invoice .first_table .top>*:first-of-type {
  border-left: 1px solid #ccc;
}

.invoice .first_table .bottom {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 10px;
}

.invoice .first_table .bottom::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0px;
  height: 100%;
  width: 1px;
  background-color: #ccc;
}

.invoice .first_table .bottom>* {
  width: 50%;
  text-align: left;
}

.invoice .first_table .bottom>* * {
  text-align: left;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
}

.invoice .first_table .bottom .right {
  padding-left: 4px;
}

.date_info * {
  text-align: left;
}

.other_data>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  border: 1px solid #ccc;
  padding: 4px;
}

.comment_one {
  text-align: left;
  margin-top: 10px;
  padding: 10px;
}

.comment_one * {
  text-align: left;
}

.first_table {}


svg.pharmcode text {
  display: none !important;
}

@media print {
  .invoiceContainer {
    page-break-after: always;
  }


  .arabicinvoice table{
    direction: rtl !important;
    max-width: 100%;
    color: black !important;
  }

  

}
.new_head {
  justify-content: flex-start !important;
}

@media print {
  body, .print_page, #ID-of-div-with-long-content {
  scale: .6;
  }
}