.data_filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  padding: 30px;
}

.filter-item {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 300px;
}

.filter-item label {
  font-size: 16px;
  margin-right: 8px;
  color: #333;
}

.filter-item select,
.filter-item input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px; /* تحسين حجم النص */
  width: 100%;
}

.apply-filter-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.apply-filter-btn:hover {
  background-color: #45a049;
}

.rowDivBtns {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 0 30px;
}
