.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* background-color: #333; */
  color: #fff;
  box-shadow: 1px 2px 24px -2px rgba(128, 128, 128, 0.342);
  height: 90px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 131231 !important;
}

.logo {
  font-size: 24px;
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;
  position: relative;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 8px 0;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  background-color: #f0f0f0;
}

.logo input {
  width: min(800px, 100%) !important;
}

.header-logo input {
  padding: 10px 20px;
  outline: none;
  border-radius: 5px;
  border: 2px solid darkgrey;
}

.header-logo,
.header-logo input {
  width: min(600px, 100%);
}
.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top position as needed */
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: block;
}
.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 8px 0;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  background-color: #f0f0f0;
}

.profile span {
  width: 70px;
  height: 70px;
  border-radius: 90px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  box-shadow: 1px 1px 24px -2px darkgrey;
}

.dropdown {
  float: left;
  /* overflow: hidden; */
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
}

.container a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content ul {
  margin: 0;
}

.dropdown:hover .dropdown-content {
  display: flex !important;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  left: 0;
  padding: 20px;
  top: 110%;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  z-index: 13123123123231 !important;
  background: white;
}

.dropdown-content::before {
  width: 20px;
  content: '';
  height: 20px;
  background: white;
  position: absolute;
  z-index: 2123;
  top: -10%;
  left: 23px;
  rotate: 45deg;
}
