.add_info_container {
  padding: 30px;
}

.add_product_container form .fields {}

form .fields .field_input {}

.make_res_ali {
  align-items: start;
}

/*  */

.ing_container {
  display: flex;
  width: 100%;
  align-items: center;
}

.ingrediant {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

/* .ingrediant > div {
  width: 50%;
} */
.ingrediant>div>input {
  width: 100%;

  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.1s ease;
  border: 1px solid rgb(10, 158, 55);
  padding: 5px 4px;
}

.ingrediant>div>input:focus {
  border: 1px solid rgb(6, 107, 36);
  box-shadow: 0px 0px 3px 1px #009419ad;
}

.deleteIng {
  cursor: pointer;
  background: rgba(173, 3, 3, 0.717);
  padding: 0;
  margin: 0;
  height: fit-content;
  color: #fff;
  border-radius: 50%;
  padding: 1px;
  margin-left: 10px;
}

.prod_container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.prod_container .field_input {
  flex-grow: 1;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
  border-radius: 17px;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: -1.3px;
  left: -4px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.toggle-input:checked+.toggle-label {
  background-color: #86d993;
}

.toggle-input:checked+.toggle-label:before {
  transform: translateX(26px);
}


.images_container {}

.images_container .images {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  flex-wrap: wrap;
}

.images_container .images .image {
  width: 100px;
  position: relative;
}

.delete_btn {
  position: absolute;
  top: 0;
  padding: 2px;
  right: 0;
  width: 25px;
  transform: translate(50%, -50%);
  background-color: #7c1010c3;
  color: #fff;
  border-radius: 50%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.images_container .images .image .avatar {
  cursor: pointer;
  transition: .3s ease;
  border-radius: 5px;
  padding: 10px;
}

.images_container .images .image .avatar:hover {
  box-shadow: 0px 0px 10px #a9a9a9;

}

.images_container .images .image>img {
  width: 100%;

}