.rates div:first-of-type label {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
}

.rates div:nth-of-type(2) {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.rates div:nth-of-type(2) svg {
  cursor: pointer;
}