



.select-container > input{
  width: 100%;

}
.options {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 5px 10px;
  box-shadow: 2px 2px 20px rgb(161, 161, 161);
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  position: absolute;
  background-color: #FFF;
  z-index: 2;
  width: 100%;
  max-height: 300px;
  overflow: auto;

}


.searchItem{
  padding: 4px 5px;
  transition: .1s ease;
  border-radius: 5px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}
.searchItem:hover{
  background-color: rgb(238, 237, 237);
}


.searchItem-separator{
  margin: 0 10px;
}