@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Playball&family=Protest+Revolution&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
:root {
  --main-color: #ef0a27;
}

body {
  padding: 0;
  margin: 0;
}

* {
  position: relative;
  scroll-behavior: smooth;
  direction: rtl;
  font-family: "Tajawal", sans-serif;

  text-align: right;
}

.loader .box {
  width: 100%;
  height: 100%;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
}

/* styles add by m_sayed */
/* styles add by m_sayed */
/* styles add by m_sayed */
/* styles add by m_sayed */

.page_padding {
  padding: 30px;
}

.table_row_image {
  width: 40px;
}
.table_row_image img {
  width: 100%;
  object-fit: cover;
}

.title_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_add svg {
  color: #0e6696;
  font-size: 30px;
  width: 30px;
  height: 30px;
  transition: 0.1s ease;
  cursor: pointer;
}
.title_add svg:hover {
  width: 32px;
  height: 32px;
}

.inputs {
  display: flex;
  /* align-items: center; */
  gap: 20px;
  flex-direction: column;
}

.field_input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input,
select,
textarea {
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.1s ease;
  border: 1px solid rgb(10, 158, 55);
  padding: 5px 4px;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid rgb(6, 107, 36);
  box-shadow: 0px 0px 3px 1px #009419ad;
}

.field_input input,
.field_input select,
.field_input textarea {
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.1s ease;
  border: 1px solid rgb(10, 158, 55);
  padding: 5px 4px;
}

.field_input input:focus,
.field_input select:focus,
.field_input textarea:focus {
  border: 1px solid rgb(6, 107, 36);
  box-shadow: 0px 0px 3px 1px #009419ad;
}

.toasterContainerTwo {
  z-index: 12312143423234328427344324 !important;
}

.select__input {
  border: none;
  box-shadow: none !important;
}
