/* Add any other global styles you have */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 112312323123; /* Ensure the overlay is above other content */
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: min(500px, 96%);
  z-index: 2112312323123210;
  display: flex;
  max-height: 90vh;
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-start;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary, #009418);

  /* web-h1 */
  font-family: Tajawal;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.exitModal {
  cursor: pointer;
}

.modalChildren {
  /* Add any styles you want for the modal content */
}

.popup_agree_btn,
.popup_refuse_btn {
  padding: 5px 10px;
  background-color: #009418;
  margin-top: 20px;
  color: #fff;
  border-radius: 3px;
  margin-left: 20px;
}

.popup_refuse_btn {
  background-color: #851709;
}

.modalButtons button {
  margin: 0;
}
.modalButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 10px 5px;
}
