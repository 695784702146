.color_picker {
  border: none !important;
  outline: none !important;
  all: unset !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 23% !important;
  /* border: 2px solid red; */
  overflow: hidden !important;
  cursor: pointer !important;
}

.searchInput {
  width: 100%;
  margin: 20px 0px;
}
.searchInput > input {
  width: 100%;
}

.custom_loader .rs-loader-spin {
  border-top-color: #222 !important;
}

.MR_input_form_element.lazy {
  border: none !important;
}
